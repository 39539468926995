var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.key}` },
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    staticClass: "mr-3",
                    staticStyle: { "font-size": "1.6rem" },
                  },
                  [_c("i", { staticClass: "flaticon2-calendar-2" })]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "left-buttons",
            fn: function () {
              return [_c("MarkingPeriodSubHeaderMenu")]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "kt-container kt-grid__item kt-grid__item--fluid" },
        [
          _c("div", { staticClass: "row" }, [
            _vm.markingPeriods.length == 0
              ? _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "alert alert-light alert-elevate fade show",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm._m(0),
                      _c(
                        "div",
                        { staticClass: "alert-text" },
                        [
                          _vm._v(" Upload a "),
                          _c(
                            "router-link",
                            {
                              staticClass: "kt-font-bold kt-font-primary",
                              attrs: { to: { name: "UploadReportCards" } },
                            },
                            [_vm._v(" Stars Admin All Teachers ")]
                          ),
                          _vm._v(
                            " excel file and enable STARS Grading on the marking period to enable this export utility. "
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _c("div", { staticClass: "kt-portlet" }, [
                  _c("div", { staticClass: "kt-portlet__body" }, [
                    _c(
                      "div",
                      {
                        staticClass: "kt-grid kt-wizard-v1 kt-wizard-v1--white",
                        attrs: {
                          id: "kt_wizard_v1",
                          "data-ktwizard-state": "first",
                        },
                      },
                      [
                        _c("div", { staticClass: "kt-grid__item" }, [
                          _c("div", { staticClass: "kt-wizard-v1__nav" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "kt-wizard-v1__nav-items kt-wizard-v1__nav-items--clickable",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "kt-wizard-v1__nav-item",
                                    attrs: {
                                      "data-ktwizard-type": "step",
                                      "data-ktwizard-state":
                                        _vm.getClassForStep("download"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectStepByName("download")
                                      },
                                    },
                                  },
                                  [_vm._m(1)]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "kt-wizard-v1__nav-item",
                                    attrs: {
                                      "data-ktwizard-type": "step",
                                      "data-ktwizard-state":
                                        _vm.getClassForStep("copy"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectStepByName("copy")
                                      },
                                    },
                                  },
                                  [_vm._m(2)]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "kt-wizard-v1__nav-item",
                                    attrs: {
                                      "data-ktwizard-type": "step",
                                      "data-ktwizard-state":
                                        _vm.getClassForStep("paste"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectStepByName("paste")
                                      },
                                    },
                                  },
                                  [_vm._m(3)]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "kt-wizard-v1__nav-item",
                                    attrs: {
                                      "data-ktwizard-type": "step",
                                      "data-ktwizard-state":
                                        _vm.getClassForStep("upload"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectStepByName("upload")
                                      },
                                    },
                                  },
                                  [_vm._m(4)]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper",
                          },
                          [
                            _c(
                              "form",
                              {
                                staticClass: "kt-form",
                                attrs: {
                                  id: "kt_form",
                                  onsubmit: "return false",
                                },
                              },
                              [
                                _vm.activeStep.name == "download"
                                  ? _c(
                                      "div",
                                      { staticClass: "kt-wizard-v1__content" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-heading kt-heading--md",
                                          },
                                          [_vm._v(" Download File ")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-form__section kt-form__section--first",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "kt-wizard-v1__form",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _vm.downloaded
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "alert alert-secondary w-100",
                                                          },
                                                          [
                                                            _vm._m(5),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "alert-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Great, now open the file you just downloaded in Excel and click next step button below to continue "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-8",
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "mp",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Marking Period"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.schoolTermMarkingPeriodId,
                                                                expression:
                                                                  "schoolTermMarkingPeriodId",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.schoolTermMarkingPeriodId =
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                              },
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.markingPeriods,
                                                            function (mp) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key: mp.schoolTermMarkingPeriodId,
                                                                  domProps: {
                                                                    value:
                                                                      mp.schoolTermMarkingPeriodId,
                                                                    selected:
                                                                      mp.schoolTermMarkingPeriodId ==
                                                                      _vm.schoolTermMarkingPeriodId,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        mp.markingPeriodName
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-4",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(" "),
                                                        ]),
                                                        _c("br"),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-success",
                                                            class: {
                                                              "kt-spinner kt-spinner--sm kt-spinner--light":
                                                                _vm.populating,
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                !_vm.schoolTermMarkingPeriodId,
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.download()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Download EGG "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.activeStep.name == "copy"
                                  ? _c(
                                      "div",
                                      { staticClass: "kt-wizard-v1__content" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-heading kt-heading--md",
                                          },
                                          [
                                            _vm._v(
                                              " Copy grades to the clipboard "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-form__section kt-form__section--first w-100",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "kt-wizard-v1__form",
                                              },
                                              [
                                                !_vm.schoolTermMarkingPeriodId
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "alert alert-danger w-100",
                                                      },
                                                      [
                                                        _vm._m(6),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "alert-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Please select a Marking Period on the previous step. "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row w-100",
                                                      },
                                                      [
                                                        !_vm.csvDownloaded
                                                          ? _c(
                                                              "PortletLoader",
                                                              {
                                                                staticClass:
                                                                  "w-100",
                                                              }
                                                            )
                                                          : [
                                                              _vm.csvCopiedToClipboard
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "alert alert-secondary w-100",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "alert-icon",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "flaticon2-check-mark kt-font-success",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "alert-text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Awesome, your grades are on your clipboard, click next step button below to continue "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm.formatting
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "w-100",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Creating a STARS compatible data set. Please wait... "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "progress progress--lg",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "progress-bar kt-bg-info",
                                                                              style:
                                                                                {
                                                                                  width:
                                                                                    _vm.percentage(
                                                                                      _vm.currentItem,
                                                                                      _vm.totalItems
                                                                                    ) +
                                                                                    "%",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  role: "progressbar",
                                                                                  height:
                                                                                    "20px",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-12",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c("br"),
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          ref: "btncopy",
                                                                          staticClass:
                                                                            "btn btn-success btn-lg",
                                                                          class:
                                                                            {
                                                                              "kt-spinner kt-spinner--sm kt-spinner--light":
                                                                                _vm.populating,
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                !_vm.schoolTermMarkingPeriodId,
                                                                              type: "button",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.copyToClipboard()
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "flaticon2-copy",
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " Copy Grades to Clipboard "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                            ],
                                                      ],
                                                      2
                                                    ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.activeStep.name == "paste"
                                  ? _c(
                                      "div",
                                      { staticClass: "kt-wizard-v1__content" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-heading kt-heading--md",
                                          },
                                          [
                                            _vm._v(
                                              " Paste Grades into Excel File "
                                            ),
                                          ]
                                        ),
                                        _vm._m(7),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.activeStep.name == "upload"
                                  ? _c(
                                      "div",
                                      { staticClass: "kt-wizard-v1__content" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-heading kt-heading--md",
                                          },
                                          [
                                            _vm._v(
                                              " Submit the Excel File to STARS Admin "
                                            ),
                                          ]
                                        ),
                                        _vm._m(8),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "kt-form__actions mt-5 pt-5" },
                                  [
                                    _vm.activeStep.name !== "download"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u",
                                            attrs: {
                                              "data-ktwizard-type":
                                                "action-prev",
                                            },
                                            on: { click: _vm.previousStep },
                                          },
                                          [_vm._v(" Previous ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u",
                                        attrs: {
                                          "data-ktwizard-type": "action-next",
                                        },
                                        on: { click: _vm.nextStep },
                                      },
                                      [_vm._v(" Next Step ")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "la la-info-circle kt-font-danger" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-wizard-v1__nav-body" }, [
      _c("div", { staticClass: "kt-wizard-v1__nav-icon" }, [
        _c("i", { staticClass: "flaticon-multimedia-4" }),
      ]),
      _c("div", { staticClass: "kt-wizard-v1__nav-label" }, [
        _vm._v(" 1. Download Egg "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-wizard-v1__nav-body" }, [
      _c("div", { staticClass: "kt-wizard-v1__nav-icon" }, [
        _c("i", { staticClass: "flaticon-edit-1" }),
      ]),
      _c("div", { staticClass: "kt-wizard-v1__nav-label" }, [
        _vm._v(" 2. Copy Grades "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-wizard-v1__nav-body" }, [
      _c("div", { staticClass: "kt-wizard-v1__nav-icon" }, [
        _c("i", { staticClass: "flaticon-imac" }),
      ]),
      _c("div", { staticClass: "kt-wizard-v1__nav-label" }, [
        _vm._v(" 3. Paste Grades "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-wizard-v1__nav-body" }, [
      _c("div", { staticClass: "kt-wizard-v1__nav-icon" }, [
        _c("i", { staticClass: "flaticon-upload-1" }),
      ]),
      _c("div", { staticClass: "kt-wizard-v1__nav-label" }, [
        _vm._v(" 4. Save And Upload "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon2-check-mark kt-font-success mb-5" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon2-check-mark kt-font-danger mb-5" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "kt-form__section kt-form__section--first w-100" },
      [
        _c("div", { staticClass: "row w-100" }, [
          _c("div", { staticClass: "alert alert-secondary w-100" }, [
            _c("div", { staticClass: "alert-icon" }, [
              _c("i", { staticClass: "flaticon-edit-1 kt-font-primary" }),
            ]),
            _c("div", { staticClass: "alert-text" }, [
              _vm._v(
                " Open the excel file you downloaded in Step 1, and place your cursor in row 2, column J. "
              ),
              _c("br"),
              _vm._v(
                " Then paste the grade data that is saved on your clipboard. "
              ),
            ]),
          ]),
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: "/images/hs_egg.png" },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "kt-form__section kt-form__section--first w-100" },
      [
        _c("div", { staticClass: "row w-100" }, [
          _c("div", { staticClass: "alert alert-secondary w-100" }, [
            _c("div", { staticClass: "alert-icon" }, [
              _c("i", { staticClass: "flaticon2-check-mark kt-font-primary" }),
            ]),
            _c("div", { staticClass: "alert-text" }, [
              _vm._v(
                " Save the changes to your excel file, and submit this file back via the STARS Client. "
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }